.contact__container{
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info{
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.contact__card-icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data{
    font-size: var(--small-font-size);
}

.contact__card-title{
    font-weight: var(--font-medium);
}

.contact__card-data{
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-block;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon{
    transform: translate(0.25rem);
}

.contact__form{
    width: 360px;
}

.contact__form-div{
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0,0,0,0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag{
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area{
    height: 11rem;
}

.contact__form-area textarea{
    resize: none;
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__container{
        column-gap: 3rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .contact__container{
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact__info{
        justify-content: center;
    }

    .contact__form{
        margin: 0 auto;
    }

    .button--flex{
        width: 100%;
        justify-content: center;
    }

    
  }
  
  @media screen and (max-width: 576px) {
    .contact__info{
        grid-template-columns: 1fr;

    }

    .contact__form{
        width: 100%;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    
  }
  


  .formbold-mb-5 {
    margin-bottom: 20px;
  }
  .formbold-pt-3 {
    padding-top: 12px;
  }
  .formbold-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
   

  }

  .formbold-form-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 1.5rem;
  }
  .formbold-form-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    margin-bottom: 12px;
  }
  .formbold-form-label-2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .formbold-form-input {
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
  }
  .formbold-form-input:focus {
    border-color: #6a64f1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold-btn {
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 14px 32px;
    border: none;
    font-weight: 600;
    background-color: #6a64f1;
    color: white;
    cursor: pointer;
  }
  .formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold--mx-3 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .formbold-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .w-full {
    width: 100%;
  }
  @media (min-width: 540px) {
    .sm\:w-half {
      width: 50%;
    }
  }